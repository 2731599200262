import {initTabletOpenPortrait} from "./init-tablet-open-portrait";
import {initTabletOpenLandscape} from "./init-tablet-open-landscape";
import {initTabletClosePortrait} from "./init-tablet-close-portrait";
import {initTabletCloseLandscape} from "./init-tablet-close-landscape";
import {initTabletValue} from "./init-tablet-value";


export const initStateTablet = () => {

    let initialAngle;
    let domElement;
    let angle;
    let sidestatus;

    const {
        sidebarColumnsPortrait,
        mainColumnsPortrait,
        wholeLeftPositionOpen,
        wholeLeftPositionCloseLandscape,
        wholeLeftPositionClosePortrait,
        sidebarColumnsLandscape,
        mainColumnsLandscape,
        mainColumnsFillwidth
    } = initTabletValue();

    ///////////////////////////////////////////////////////
    // Load時にdeviceの向きを検知して、locastorageに保存
    ///////////////////////////////////////////////////////

    window.addEventListener('load', () => {
        detectOrientation();
        //ここを新しく加えた。
        document.body.classList.add('loaded');
    });

    function detectOrientation() {
      if (window.innerWidth > window.innerHeight) {
        // console.log('横向き（Landscape）');
        initialAngle = "landscape"
        localStorage.setItem('orientationAngle', initialAngle);

      } else {
        // console.log('縦向き（Portrait）');
        initialAngle = "portrait"
        localStorage.setItem('orientationAngle', initialAngle);

      }
    }

    sidestatus = localStorage.getItem('toggleSlider');
    angle = localStorage.getItem('orientationAngle');

    if (sidestatus === null) {
      localStorage.setItem('toggleSlider', 'open');
    }


    ///////////////////////////////////////////////////////
    // loadされたときに、locastorageの値によって呼び出される初期設定
    ///////////////////////////////////////////////////////

        if (sidestatus === 'open' && angle === 'landscape') {

            domElement = initTabletOpenLandscape(wholeLeftPositionOpen, sidebarColumnsLandscape, mainColumnsLandscape);

        } else if (sidestatus === 'open' && angle === 'portrait') {

            domElement = initTabletOpenPortrait(wholeLeftPositionOpen, sidebarColumnsPortrait, mainColumnsPortrait);

        } else if (sidestatus === 'close' && angle === 'landscape') {

            domElement = initTabletCloseLandscape(wholeLeftPositionCloseLandscape, sidebarColumnsLandscape, mainColumnsFillwidth);

        } else if (sidestatus === 'close' && angle === 'portrait') {

            domElement = initTabletClosePortrait(wholeLeftPositionClosePortrait, sidebarColumnsPortrait, mainColumnsFillwidth);

        }

        return domElement
}

