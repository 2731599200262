
////////////////////////////////////////
// defaultはopenにする。
// localStorageにsideStatusが無かった時もopenにする。
// PCの場合、angleは必要ないけど一応setItemしておく。
////////////////////////////////////////


export const checkLocalStorage = () => {

    let sideStatus = localStorage.getItem('toggleSlider');
    let angle = localStorage.getItem('orientationAngle');

    if (sideStatus === null || sideStatus === undefined || sideStatus === 'open') {
            localStorage.setItem('toggleSlider', 'open');
    }else if(sideStatus === 'close') {
            localStorage.setItem('toggleSlider', 'close');
    }

    if(angle === 'landscape') {
        localStorage.setItem('orientationAngle', 'landscape');
    }else if(angle === null || angle === undefined || angle === 'portrait') {
        localStorage.setItem('orientationAngle', 'portrait');
    }


    return {sideStatus, angle}

}





