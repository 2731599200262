import {initQueryselectorTablet} from "./init-queryselector-tablet";

export const initTabletClosePortrait = (wholeLeftPositionClosePortrait, sidebarColumnsPortrait, mainColumnsFillwidth) => {

        const { body, grid, sideMenu, sideBarButton, main, page, codeArticle } = initQueryselectorTablet();

        grid.style.left = wholeLeftPositionClosePortrait + "vw"
        grid.style.gridTemplateColumns = sidebarColumnsPortrait + "vw" + " " + mainColumnsFillwidth + "vw";

        if (codeArticle.length > 0) {
            for (let i = 0; i < codeArticle.length; i++) {
                codeArticle[i].style.width = "100%";
            }
        }

        sideBarButton.textContent = "OPEN";

        return { body, grid, sideMenu, sideBarButton, main, page, codeArticle }
}