import { MovingGotoOpenPCInit } from '../../pc/move/moving-goto-open-pc-init';
import { MovingGotoClosePC } from '../../pc/move/moving-goto-close-pc';


export const detectSize = () => {

  const sideStatus = localStorage.getItem('toggleSlider');

  var browserWidth = window.innerWidth;
  // console.log('ブラウザーの横幅は' + browserWidth + 'pxです。');

  if(browserWidth < 768 ) {
    // console.log('スマホです。');
    MovingGotoClosePC();
  } else if(browserWidth >= 768 && sideStatus === 'open') {
    // console.log('PCです。');
    MovingGotoOpenPCInit();
  }else if(browserWidth >= 768 && sideStatus === 'close') {
    // console.log('PCです。');
    MovingGotoClosePC();
  }
}