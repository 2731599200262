import {initQueryselectorPC} from '../init-queryselector-pc.js';

export const MovingGotoClosePC = (rem) => {

    const {grid, sideMenu, sideBarButton, main} = initQueryselectorPC();

    grid.style.left = "-30rem"
    sideMenu.style.left = "-26rem";
    sideBarButton.style.left = "-6px";
    main.style.left = "4rem";

    // main.style.width = "calc(100% - 6rem)";

    sideMenu.style.transition = '0.5s';
    sideBarButton.style.transition = '0.5s';
    main.style.transition = '0.5s';

    sideBarButton.textContent = "OPEN";

    let toggleSlider = "close";
    localStorage.setItem('toggleSlider', toggleSlider);
}