import { checkLocalStorage } from "./base/side-base";
import { detectSize } from "./base/pc/detect-size";
import { detectResize } from "./base/pc/detect-resize";
import { initStatePC } from "./pc/side-pc-initstate";
import {measurePositionPC} from "./pc/measure-position-pc";
import {MovingFromOpenPC} from "./pc/move/moving-from-open-pc";
import {MovingFromClosePC} from "./pc/move/moving-from-close-pc";
import {MovingGotoClosePC} from "./pc/move/moving-goto-close-pc";
import {MovingGotoOpenPC} from "./pc/move/moving-goto-open-pc";


////////////////////////////////////////
// PCが呼び出されたときの起点。
////////////////////////////////////////

export const pcSidebar = () => {

    // let startX;
    // let angle;

    const checkStatus = checkLocalStorage();
    const {sideStatus} = checkStatus;

/////////////////////////
// Load時にBrowserの幅を判別する ここはmedia queryで判別した方がよいのでは？
/////////////////////////

    window.addEventListener('load', detectSize);

// const sideBarHammer = new Hammer(sideMenu);

/////////////////////////
// Browserのresize判別する
/////////////////////////

    window.addEventListener('resize', detectResize);


/////////////////////////
// initStatePCで、cssの初期状態を設定する
/////////////////////////
    const sideBarMove = initStatePC(sideStatus);
    const {sideMenu} = sideBarMove;


    const sideBarHammer = new Hammer(sideMenu);

/////////////////////////
// PanLeft
/////////////////////////

    // sideBarHammer.on('panleft', function (ev) {
    //
    //     const statusVw = measurePositionPC(ev);
    //     const {sidestatus, rem} = statusVw;
    //
    //     // console.log('panleft', rem);
    //
    //     if (sidestatus === "open") {
    //         // console.log("sidepanleft", statusVw.rem)
    //         if (rem <= 0 && rem >= -20) {
    //             // console.log("sidepanleftinside", rem)
    //             MovingFromOpenPC(rem);
    //         } else if (rem <= -20 && rem >= -30) {
    //             MovingGotoClosePC();
    //         }
    //     } else if (sidestatus === "close") {
    //         if (rem >= 0) {
    //             MovingFromClosePC(rem);
    //         }
    //     }
    // });

/////////////////////////
// Panright
/////////////////////////

    // sideBarHammer.on('panright', function (ev) {
    //
    //     const statusVw = measurePositionPC(ev);
    //     const {sidestatus, rem} = statusVw;
    //     // console.log("sidepanright", rem)
    //
    //     //開いているとき
    //     if (sidestatus === "open") {
    //         if (rem <= 0) {
    //             //　一度左に移動し、その後右に移動した場合。左の開いた定位置以上には右にいかないようになっている。
    //             MovingFromOpenPC(rem);
    //         }
    //         //閉じているとき。右に移動した場合。これが普通の動作
    //     } else if (sidestatus === "close") {
    //         // console.log("sidestatus", sidestatus)
    //         if (rem >= 0 && rem <= 20) {
    //             MovingFromClosePC(rem)
    //         } else if (rem >= 20 && rem <= 30) {
    //             MovingGotoOpenPC();
    //         }
    //     }
    // });

/////////////////////////
// PanEnd
/////////////////////////

    // sideBarHammer.on('panend', function (ev) {
    //
    //     const statusVw = measurePositionPC(ev);
    //     const {sidestatus, rem} = statusVw;
    //
    //     if (sidestatus === "open") {
    //         if (rem <= 0 && rem >= -20) {
    //             MovingGotoOpenPC();
    //         }
    //     } else if (sidestatus === "close") {
    //         if (rem >= 0 && rem <= 20) {
    //             MovingGotoClosePC();
    //         }
    //     }
    // });

/////////////////////////
// Clickした時
/////////////////////////

    sideBarMove.sideBarButton.addEventListener('click', function (event) {

        const sidestatus = localStorage.getItem('toggleSlider');

        if (sidestatus === "open") {

            MovingGotoClosePC();

        } else if (sidestatus === "close") {

            MovingGotoOpenPC();

        }
    });
}