import {MovingGotoCloseMobile} from './sp/move/moving-goto-close-mobile';
import {initSpValue} from "./sp/init-sp-value";



export function linksetTimeout(link) {

    const {
        sidemenuLeftPositionClosePortrait,
        sidemenuGridMainColumnsPortrait,
        sidemenuButtonColumns,
        mainLeftLandscape
    } = initSpValue();

    // setTimeout(function () {
        MovingGotoCloseMobile(
            sidemenuLeftPositionClosePortrait,
            sidemenuGridMainColumnsPortrait,
            sidemenuButtonColumns,
            mainLeftLandscape
        );
    // }, 1000); // MovingGotoCloseMobile関数を1秒後に実行

    setTimeout(function () {
        window.location.href = link.href;
    }, 1000); // さらに1秒後にリンクに移動
}

export function linksetTimeoutSummary(scrollLink) {

    const {
        sidemenuLeftPositionClosePortrait,
        sidemenuGridMainColumnsPortrait,
        sidemenuButtonColumns,
        mainLeftLandscape
    } = initSpValue();

    MovingGotoCloseMobile(
        sidemenuLeftPositionClosePortrait,
        sidemenuGridMainColumnsPortrait,
        sidemenuButtonColumns,
        mainLeftLandscape
    );
    setTimeout(function () {
        // window.location.href = link.href;
    }, 1000); // 1秒待機
}

