

export const initQueryselectorPC = () => {

    let grid = document.querySelector('.grid');
    let sideMenu = document.querySelector('.sidebar');
    const sideBarButton = document.querySelector('.opcl-button');
    const main = document.querySelector('.main');

    return {grid, sideMenu, sideBarButton, main}
}
