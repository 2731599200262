import {initQueryselectorTablet} from "./init-queryselector-tablet";

export const initTabletCloseLandscape = (wholeLeftPositionCloseLandscape, sidebarColumnsLandscape, mainColumnsFillwidth) => {

        const { body, grid, sideMenu, sideBarButton, main, page, codeArticle } = initQueryselectorTablet();

        grid.style.left = wholeLeftPositionCloseLandscape + "vw"
        grid.style.gridTemplateColumns = sidebarColumnsLandscape + "vw" + " " + mainColumnsFillwidth + "vw";

        if (codeArticle.length > 0) {
            for (let i = 0; i < codeArticle.length; i++) {
                codeArticle[i].style.width = "100%";
            }
        }

        sideBarButton.textContent = "OPEN";


        return { grid, sideMenu, sideBarButton, main, page, codeArticle }
}