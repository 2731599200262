import { initQueryselectorTablet } from "../init-queryselector-tablet";

export const MovingGotoCloseTabletHorizonal = (wholeLeftPositionCloseLandscape, sidebarColumnsLandscape, mainColumnsFillwidth) => {

    const { body, grid, sideMenu, sideBarButton, main, page, codeArticle } = initQueryselectorTablet();

    grid.style.left = wholeLeftPositionCloseLandscape + "vw"
    grid.style.gridTemplateColumns = sidebarColumnsLandscape + "vw " + mainColumnsFillwidth + "vw"

    if(page !== null){
        page.style.width = "calc(100vw - 4vw)";
        page.style.transition = '0.5s';
    }

    grid.style.transition = '0.5s';

    sideBarButton.textContent = "OPEN";

    let toggleSlider = "close";
    localStorage.setItem('toggleSlider', toggleSlider);
}