import {initQueryselectorSp} from '../init-queryselector-sp.js';
import {enableScrolling} from '../scroll/enable-scrolling.js';

export const MovingGotoCloseMobile = (
        sidemenuLeftPositionClosePortrait,
        sidemenuGridMainColumnsPortrait,
        sidemenuButtonColumns,
        mainLeftLandscape
    ) => {

    const {sideMenu, sideBarButton, main, sidebarHandle} = initQueryselectorSp();
    sidebarHandle.style.width = "5rem"

    // sideMenu.style.left = "-70vw";
    sideMenu.style.left = sidemenuLeftPositionClosePortrait + "vw";
    // sideMenu.style.gridTemplateColumns = "70vw 5rem";
    sideMenu.style.gridTemplateColumns = sidemenuGridMainColumnsPortrait + "vw" + " " + sidemenuButtonColumns + "rem";
    sideBarButton.style.left = "-6px";
    // main.style.width = "calc(100% - 5rem)";
    main.style.left = mainLeftLandscape + "rem";
    main.style.opacity = "1";


    sideMenu.style.transition = '0.5s';
    sideBarButton.style.transition = '0.5s';
    sidebarHandle.style.transition = '0.5s';
    main.style.transition = "0.5s";

    enableScrolling();

    sideBarButton.textContent = "OPEN";

    const toggleSlider = "close";
    localStorage.setItem('toggleSlider', toggleSlider);
}