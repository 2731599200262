import {initQueryselectorTablet} from "./init-queryselector-tablet";

export const initTabletOpenLandscape = (wholeLeftPositionOpen, sidebarColumnsLandscape, mainColumnsLandscape) => {

        const { body, grid, sideMenu, sideBarButton, main, page, codeArticle } = initQueryselectorTablet();

        grid.style.left = wholeLeftPositionOpen + "vw";
        grid.style.gridTemplateColumns = sidebarColumnsLandscape + "vw" + " " + mainColumnsLandscape +"vw";

        if (codeArticle.length > 0) {
            for (let i = 0; i < codeArticle.length; i++) {
                codeArticle[i].style.width = "100%"
            }
        }

        sideBarButton.textContent = "CLOSE";

        return { body, grid, sideMenu, sideBarButton, main, page, codeArticle }
}