import { initQueryselectorTablet } from "../init-queryselector-tablet";

export const MovingGotoOpenTabletHorizonal = (wholeLeftPositionOpen, sidebarColumnsLandscape, mainColumnsLandscape) => {

    const { body, grid, sideMenu, sideBarButton, main, page, codeArticle } = initQueryselectorTablet();

    grid.style.left = wholeLeftPositionOpen
    grid.style.gridTemplateColumns = sidebarColumnsLandscape + "vw " + mainColumnsLandscape + "vw"

    if(page !== null){
        page.style.width = "calc(100% - 24vw - 6px)";
        page.style.transition = '0.5s';
    }

    grid.style.transition = '0.5s';
    sideBarButton.textContent = "CLOSE";
    body.style.opacity = "1";

    let toggleSlider = "open";
    localStorage.setItem('toggleSlider', toggleSlider);
}
