
export const initStatePC = (sideStatus) => {

    let grid = document.querySelector('.grid');
    let sideMenu = document.querySelector('.sidebar');
    const sideBarButton = document.querySelector('.opcl-button');
    const main = document.querySelector('.main');
    const body = document.querySelector('body');

    if(sideStatus === 'open') {

        // console.log("open")
        grid.style.left = "0"
        sideMenu.style.left = "0";
        main.style.left = "0";
        sideBarButton.style.left = "calc(26rem - 6px)";
        main.style.width = "auto";
        sideBarButton.textContent = "CLOSE";

    }else if(sideStatus === 'close') {

        // console.log("close")
        grid.style.left = "-30rem"
        sideMenu.style.left = "-26rem";
        main.style.left = "4rem";
        // main.style.width = "calc(100% - 6rem)";
        sideBarButton.style.left = "-6px";
    }

    body.style.opacity = "1";
    return {grid, sideMenu, sideBarButton, main}
}

