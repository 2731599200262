import {initQueryselectorSp} from '../init-queryselector-sp.js';
import {enableScrolling} from '../scroll/enable-scrolling.js';

export const MovingGotoCloseMobileLandscape = (
        sidemenuButtonColumns,
        sidemenuLeftPositionOpenLandscape,
        sidemenuGridMainColumnsLandscape,
        mainLeftLandscape,
        gridMainColumnsPortrait,
        mainWidthLandscape
    ) => {

    const {sideMenu, sideBarButton, main, sidebarHandle} = initQueryselectorSp();

    sidebarHandle.style.width = sidemenuButtonColumns + "rem";

    sideMenu.style.left = sidemenuLeftPositionOpenLandscape + "vw";
    sideMenu.style.gridTemplateColumns = sidemenuGridMainColumnsLandscape + "vw" + " " + sidemenuButtonColumns + "rem";
    sideBarButton.style.left = "-6px";
    main.style.left = mainLeftLandscape + "rem";
    main.style.width = "calc(" + gridMainColumnsPortrait + "vw - " + mainWidthLandscape + "rem)";
    main.style.opacity = "1";

    sideMenu.style.transition = '0.5s';
    sideBarButton.style.transition = '0.5s';
    sidebarHandle.style.transition = '0.5s';
    main.style.transition = "0.5s";

    enableScrolling();

    sideBarButton.textContent = "OPEN";

    const toggleSlider = "close";
    localStorage.setItem('toggleSlider', toggleSlider);
}