import { MovingGotoCloseMobileLandscape } from '../../sp/move/moving-goto-close-mobile-landscape.js';
import { MovingGotoCloseMobile } from '../../sp/move/moving-goto-close-mobile.js';
import {initSpValue} from "../../sp/init-sp-value";

export function detectOrientation() {

    let angle;
    let screenOrientation = localStorage.getItem("toggleSlider");
    let isPortrait;

    const {
        gridMainColumnsPortrait,

        sidemenuLeftPositionClosePortrait,
        sidemenuLeftPositionOpenLandscape,
        sidemenuButtonColumns,
        sidemenuGridMainColumnsPortrait,
        sidemenuGridMainColumnsLandscape,
        mainLeftLandscape,
        mainWidthLandscape
    } = initSpValue();


    if(typeof window.orientation !== "undefined"){
        isPortrait = Math.abs(window.orientation) === 0 || Math.abs(window.orientation) === 180;
    }else{
        isPortrait = window.innerHeight > window.innerWidth;
    }
    // const isPortrait = window.innerHeight > window.innerWidth;
    // console.log("isPortrait", isPortrait)
    if (isPortrait) {
        // console.log('モバイルは縦向きです');
        angle = "portrait"
        localStorage.setItem('orientationAngle', angle);
        if(screenOrientation==="open"){
            MovingGotoCloseMobile(
                sidemenuLeftPositionClosePortrait,
                sidemenuGridMainColumnsPortrait,
                sidemenuButtonColumns,
                mainLeftLandscape
            );
        }else if(screenOrientation==="close"){
            MovingGotoCloseMobile(
                sidemenuLeftPositionClosePortrait,
                sidemenuGridMainColumnsPortrait,
                sidemenuButtonColumns,
                mainLeftLandscape
            );
        }
    }else{
       // console.log('モバイルは横向きです');
        angle = "landscape"
        localStorage.setItem('orientationAngle', angle);
        if(screenOrientation==="open"){
            MovingGotoCloseMobileLandscape(
                sidemenuButtonColumns,
                sidemenuLeftPositionOpenLandscape,
                sidemenuGridMainColumnsLandscape,
                mainLeftLandscape,
                gridMainColumnsPortrait,
                mainWidthLandscape
            );
        }else if(screenOrientation==="close"){
            MovingGotoCloseMobileLandscape(
                sidemenuButtonColumns,
                sidemenuLeftPositionOpenLandscape,
                sidemenuGridMainColumnsLandscape,
                mainLeftLandscape,
                gridMainColumnsPortrait,
                mainWidthLandscape
            );
        }
    }

    return angle;

}