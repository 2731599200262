import {initQueryselectorSp} from '../init-queryselector-sp.js';
import {disableScrolling} from '../scroll/disable-scrolling.js';


export const MovingGotoOpenMobile = (
    sidemenuLeftPositionOpenPortrait,
    sidemenuGridMainColumnsPortrait,
    sidemenuGridVacantPortrait,
    ) => {

    const {sideMenu, sideBarButton, main, sidebarHandle} = initQueryselectorSp();

    // console.log("tate")

    sideMenu.style.left = sidemenuLeftPositionOpenPortrait + "vw";
    sideMenu.style.gridTemplateColumns = sidemenuGridMainColumnsPortrait + "vw" + " " + sidemenuGridVacantPortrait + "vw";
    // sideBarButton.style.left = "calc(70vw - 6px)";
    sideBarButton.style.left = "calc(" + sidemenuGridMainColumnsPortrait + "vw - 6px)";
    // sidebarHandle.style.width = "30vw";
    sidebarHandle.style.width = sidemenuGridVacantPortrait + "vw";
    main.style.opacity = "0.3";

    sideMenu.style.transition = '0.5s';
    sideBarButton.style.transition = '0.5s';
    sideMenu.style.transition = '0.5s';
    main.style.transition = '0.5s';

    // movePercentage = 0;
    // console.log("movePercentage", movePercentage)
    disableScrolling();

    sideBarButton.textContent = "CLOSE";

    const toggleSlider = "open";
    localStorage.setItem('toggleSlider', toggleSlider);

    localStorage.setItem('movePercentage', false);
}