export const initQueryselectorTablet = () => {

    let body = document.querySelector('body');
    let grid = document.querySelector('.grid');
    let sideMenu = document.querySelector('.sidebar');
    const sideBarButton = document.querySelector('.opcl-button');
    const main = document.querySelector('.main');
    const page = document.querySelector('.page');
    const codeArticle = document.querySelectorAll('.code__article');


    return {body, grid, sideMenu, sideBarButton, main, page, codeArticle}

}
