import {initQueryselectorSp} from '../init-queryselector-sp.js';

export const MovingGotoOpenMobileLandscape = (
            sidemenuLeftPositionOpenPortrait,
            sidemenuGridMainColumnsLandscape,
            sidemenuMainColumnsLandscape,
            sidemenuButtonColumns,
            gridMainColumnsPortrait,
            mainLeftLandscape,
            mainWidthLandscape
    ) => {

    const {sideMenu, sideBarButton, main, sidebarHandle} = initQueryselectorSp();


    sideMenu.style.left = sidemenuLeftPositionOpenPortrait + "vw";
    sideMenu.style.gridTemplateColumns = sidemenuGridMainColumnsLandscape + "vw" + " " + sidemenuButtonColumns + "rem";
    sideBarButton.style.left = "calc(" + sidemenuGridMainColumnsLandscape + "vw - 6px)";
    // sideBarButton.style.left = "-6px";
    sidebarHandle.style.width = sidemenuButtonColumns + "rem";
    // main.style.left = "calc(30vw + 5rem)";
    main.style.left = "calc(" + sidemenuGridMainColumnsLandscape + "vw + " + mainLeftLandscape + "rem)";
    main.style.width = "calc(" + gridMainColumnsPortrait + "vw - " + sidemenuGridMainColumnsLandscape + "vw - " + mainWidthLandscape + "rem)";
    main.style.opacity = "1";

    sideMenu.style.transition = '0.5s';
    sideBarButton.style.transition = '0.5s';
    sideMenu.style.transition = '0.5s';
    main.style.transition = '0.5s';

    sideBarButton.textContent = "CLOSE";

    const toggleSlider = "open";
    localStorage.setItem('toggleSlider', toggleSlider);

}