import {initQueryselectorTablet} from "./init-queryselector-tablet";

export const initTabletOpenPortrait = (wholeLeftPositionOpen, sidebarColumnsPortrait, mainColumnsPortrait) => {

        const { body, grid, sideMenu, sideBarButton, main, page, codeArticle } = initQueryselectorTablet();

        grid.style.left = wholeLeftPositionOpen + "vw"
        grid.style.gridTemplateColumns = sidebarColumnsPortrait + "vw" + " " + mainColumnsPortrait + "vw";

        if (codeArticle.length > 0) {
            for (let i = 0; i < codeArticle.length; i++) {
                codeArticle[i].style.width = "100%"
            }
        }

        sideBarButton.textContent = "CLOSE";

        return { grid, sideMenu, sideBarButton, main, page, codeArticle }
}