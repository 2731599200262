import {tabletSidebar} from "./index-tablet";
import {mobileSidebar} from "./index-mobile";
import {pcSidebar} from "./index-pc";


function getDeviceOrientation(deviceType) {
  if (typeof window.orientation === 'undefined') {
    return { deviceType, orientation: 'unknown' };
  }

  const orientation = Math.abs(window.orientation) === 90 ? 'landscape' : 'portrait';
    //このタイミングでorientationAngleをsetしないと各デバイスごとの取得特にmobileで強制的にlandscapeになる
    localStorage.setItem('orientationAngle', orientation);
  return { deviceType, orientation };
}

function detectDeviceType(userAgent) {
  const isMobile = /iPhone|iPod|Android(?=.*Mobile)|Windows Phone|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const isTablet = /iPad|Android(?!.*Mobile)|Windows(?=.*Touch)|Kindle|Silk|Tablet/i.test(userAgent);

  if (isTablet) {
    return 'tablet';
  } else if (isMobile) {
    return 'smartphone';
  } else {
    return 'pc';
  }
}

(async () => {
  try {
    const deviceType = detectDeviceType(navigator.userAgent);
    const result = getDeviceOrientation(deviceType);
    // console.log("result", result);
  } catch (error) {
    console.error(error.message);
  }
})();

export const isPortrait = () => {
  return window.matchMedia("(orientation: portrait)").matches;
};

////////////////////////////////////////
// ここが全ての起点。
// コンテンツが読み込まれるときにデバイスの種類を調べて、
// デバイスに応じた関数を呼び出します。
////////////////////////////////////////

document.addEventListener('DOMContentLoaded', () => {
  const deviceType = detectDeviceType(navigator.userAgent);

  if (deviceType === 'tablet') {
    tabletSidebar();
  } else if (deviceType === 'smartphone') {
    mobileSidebar();
  } else {
    let sideStatus = localStorage.getItem('toggleSlider');
    pcSidebar();
  }

  const scrollLinks = document.querySelectorAll('.scroll-link');

  scrollLinks.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const targetId = link.getAttribute('data-target');
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        if(detectDeviceType(navigator.userAgent) === 'smartphone' && isPortrait()) {
          setTimeout(()=>{
            targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            });
          }, 1000);
        } else {
          targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        }
      }
    });
  });

});

