import {MovingGotoClosePC} from "../../pc/move/moving-goto-close-pc";
import {MovingGotoOpenPC} from "../../pc/move/moving-goto-open-pc";


export const detectResize = () => {
  var browserWidth = window.innerWidth;
    // console.log('ブラウザーの横幅は' + browserWidth + 'pxです。');
    if(browserWidth < 768) {
        // console.log('スマホです。');
        MovingGotoClosePC();
    } else {
        // console.log('PCです。');
        MovingGotoOpenPC();
  }
}