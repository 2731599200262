import {initTabletValue} from "./init-tablet-value";
import {initTabletOpenPortrait} from "./init-tablet-open-portrait";
import {initTabletOpenLandscape} from "./init-tablet-open-landscape";
import {initTabletClosePortrait} from "./init-tablet-close-portrait";
import {initTabletCloseLandscape} from "./init-tablet-close-landscape";

    ///////////////////////////////////////////////////////
    // ここを新しく加えた！
    // 方向を変えた時に呼び出される関数
    ///////////////////////////////////////////////////////

    // window.addEventListener("orientationchange", handleOrientationChange);

    export const handleOrientationChange = () => {

            const sideStatus = localStorage.getItem('toggleSlider');

            let initialAngle;

            const {
                sidebarColumnsPortrait,
                mainColumnsPortrait,
                wholeLeftPositionOpen,
                wholeLeftPositionCloseLandscape,
                wholeLeftPositionClosePortrait,
                sidebarColumnsLandscape,
                mainColumnsLandscape,
                mainColumnsFillwidth
            } = initTabletValue();

            const orientation = screen.orientation.type;

            if (sideStatus === 'open' && orientation.includes("portrait")) {
                // console.log("open縦向き");
                initTabletOpenPortrait(wholeLeftPositionOpen, sidebarColumnsPortrait, mainColumnsPortrait);
                initialAngle = "portrait"

            } else if (sideStatus === 'open' && orientation.includes("landscape")) {
                // console.log("open横向き");
                initTabletOpenLandscape(wholeLeftPositionOpen, sidebarColumnsLandscape, mainColumnsLandscape);
                initialAngle = "landscape"

            } else if (sideStatus === 'close' && orientation.includes("portrait")) {
                // console.log("close縦向き");
                initTabletClosePortrait(wholeLeftPositionClosePortrait, sidebarColumnsPortrait, mainColumnsFillwidth);
                initialAngle = "portrait"

            } else if (sideStatus === 'close' && orientation.includes("landscape")) {
                // console.log("close横向き");
                initTabletCloseLandscape(wholeLeftPositionCloseLandscape, sidebarColumnsLandscape, mainColumnsFillwidth);
                initialAngle = "landscape"

            }

           localStorage.setItem('orientationAngle', initialAngle);
    }