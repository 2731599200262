export const initTabletValue = () => {

    let sidebarColumnsPortrait = 34;
    let mainColumnsPortrait = 66;

    let wholeLeftPositionOpen = 0;
    let wholeLeftPositionCloseLandscape = -20;
    let wholeLeftPositionClosePortrait = -29;

    let sidebarColumnsLandscape = 24;
    let mainColumnsLandscape = 76;
    let mainColumnsFillwidth = 96;

    return {
        sidebarColumnsPortrait,
        mainColumnsPortrait,
        wholeLeftPositionOpen,
        wholeLeftPositionCloseLandscape,
        wholeLeftPositionClosePortrait,
        sidebarColumnsLandscape,
        mainColumnsLandscape,
        mainColumnsFillwidth
    }
}