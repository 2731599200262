import { initQueryselectorTablet } from "../init-queryselector-tablet";

export const MovingGotoCloseTablet = (wholeLeftPositionClosePortrait, sidebarColumnsPortrait, mainColumnsFillwidth) => {

    const { body, grid, sideMenu, sideBarButton, main, page, codeArticle } = initQueryselectorTablet();

    grid.style.left = wholeLeftPositionClosePortrait + "vw"
    grid.style.gridTemplateColumns = sidebarColumnsPortrait + "vw" + " " + mainColumnsFillwidth +"vw"

    if(page !== null){
        page.style.width = "calc(100vw - 4vw)";
        page.style.transition = '0.5s';
    }

    grid.style.transition = '0.5s';

    sideBarButton.textContent = "OPEN";

    let toggleSlider = "close";
    localStorage.setItem('toggleSlider', toggleSlider);
}