export const initSpValue = () => {

    let gridSidebarColumnsPortrait = 70;
    let gridMainColumnsPortrait = 100;

    let sidemenuLeftPositionOpenPortrait = 0;
    let sidemenuLeftPositionClosePortrait = -70; //使っている
    let sidemenuLeftPositionOpenLandscape = -30;
    let sidemenuLeftPositionCloseLandscape = -30;

    let sidemenuGridMainColumnsPortrait = 70;
    let sidemenuGridVacantPortrait = 30;
    let sidemenuGridMainColumnsLandscape = 30;
    let sidemenuGridVacantLandscape = 70;

    let sidemenuMainColumnsPortrait = 70;
    let sidemenuButtonColumns = 5;
    let sidemenuMainColumnsLandscape = 30;

    let mainLeftLandscape = 5;
    let mainWidthLandscape = 5;

    // let sidemenuButtonColumnsLandscape = 5;


    let sidebarColumnsLandscape = 24;

    let mainColumnsLandscape = 76;
    let mainColumnsFullwidth = 96;

    return {
        gridSidebarColumnsPortrait,
        gridMainColumnsPortrait,
        sidemenuLeftPositionOpenPortrait,
        sidemenuLeftPositionClosePortrait,
        sidemenuLeftPositionOpenLandscape,
        sidemenuLeftPositionCloseLandscape,
        sidemenuMainColumnsPortrait,
        sidemenuButtonColumns,
        sidemenuMainColumnsLandscape,
        sidemenuGridMainColumnsPortrait,
        sidemenuGridVacantPortrait,
        sidemenuGridMainColumnsLandscape,
        sidemenuGridVacantLandscape,
        sidebarColumnsLandscape,
        mainColumnsLandscape,
        mainColumnsFullwidth,
        mainLeftLandscape,
        mainWidthLandscape
    }



}