import { checkLocalStorage } from "./base/side-base";
import { detectOrientation } from "./base/sp/detect-orientation";
import { initStateMobile } from "./sp/side-sp-initstate";
import {linksetTimeout, linksetTimeoutSummary} from "./link-settimeout";
import {panLeftFunctionLandscape} from "./sp/pan/pan-left-landscape";
import {panLeftFunction} from "./sp/pan/pan-left";
import {panRightFunctionLandscape} from "./sp/pan/pan-right-landscape";
import {panRightFunction} from "./sp/pan/pan-right";
import {panEndFunctionLandscape} from "./sp/pan/pan-end-landscape";
import {panEndFunction} from "./sp/pan/pan-end";
import {MovingGotoCloseMobileLandscape} from "./sp/move/moving-goto-close-mobile-landscape";
import {MovingGotoOpenMobileLandscape} from "./sp/move/moving-goto-open-mobile-landscape";
import {MovingGotoCloseMobile} from "./sp/move/moving-goto-close-mobile";
import {MovingGotoOpenMobile} from "./sp/move/moving-goto-open-mobile";
import {measurePositionMobile} from "./sp/measure-position-mobile";
import {isPortrait} from "./index-base";
import {initSpValue} from "./sp/init-sp-value";

//////////////////////////
//ここからmobileサイトの表示は始まる。
//////////////////////////
export const mobileSidebar = () => {

    let startX;
    // let angle;
    // let sideStatus;

    //localStorage toggleSliderの値を取得。モバイルと非モバイルで値を変更する。

    let { sideStatus, angle } = checkLocalStorage();

    /////////////////////////////////////////////
    // ページが読み込まれたときに一度だけ呼び出す
    // モバイルの向きが変更されたときに呼び出されるように登録する
    // この箇所は一度整理した方が良い
    /////////////////////////////////////////////
    window.addEventListener('orientationchange', () => {
        angle = detectOrientation();
        // console.log("angle", angle)
    });

    // ページの読み込みが完了した後に初回の向きの変更を関知させる

    const {
        gridMainColumnsPortrait,
        sidemenuLeftPositionOpenPortrait,
        sidemenuLeftPositionClosePortrait,
        sidemenuLeftPositionOpenLandscape,
        sidemenuButtonColumns,
        sidemenuMainColumnsLandscape,
        sidemenuGridMainColumnsPortrait,
        sidemenuGridVacantPortrait,
        sidemenuGridMainColumnsLandscape,
        mainLeftLandscape,
        mainWidthLandscape
    } = initSpValue();

    /////////////////////////////////////////////
    // ここがモバイルデバイスの表示の初期化。
    /////////////////////////////////////////////

    const sideBarMove = initStateMobile(sideStatus, angle);
    let {sideMenu, sideBarButton, main, sidebarBody, sidebarHandle} = sideBarMove;


    const sidebarBodyHammer = new Hammer(sidebarBody, {
        threshold: 5,
    });

    const sidebarhandleHammer = new Hammer(sidebarHandle, {
        threshold: 5,
    });

    const panRecognizer = new Hammer.Pan({
        direction: Hammer.DIRECTION_HORIZONTAL, // 水平方向のパンを検出
        threshold: 50, // しきい値を1000に設定
        pointers: 1 // 1本指でのパンを検出
    });

    const mainHammer = new Hammer.Manager(main);
    mainHammer.add(panRecognizer);

//////////////////////////
// sidebarBodyHammer
/////////////////////////

    sidebarBodyHammer.on('panstart', function (event) {
        startX = event.center.x; // パンの開始位置を取得
    });

    sidebarhandleHammer.on('panstart', function (event) {
        startX = event.center.x; // パンの開始位置を取得
    });

    mainHammer.on('panstart', function (event) {
        startX = event.center.x; // パンの開始位置を取得
    });


//////////////////////////
// sidebar link function
/////////////////////////

    let linkLogo = document.querySelector('.side__logo__link');
    linkLogo.addEventListener('click', function (e) {
        e.preventDefault();
        // console.log("ssssss")
        linksetTimeout(linkLogo);
    });

    let linkTag = document.querySelectorAll('.side__tag');
    linkTag.forEach(function (linkTag) {
        linkTag.addEventListener('click', function (e) {
            e.preventDefault();
            linksetTimeout(linkTag);
        });
    });

    let linkSns = document.querySelectorAll('.side__sns__link');
    linkSns.forEach(function (linkSns) {
        linkSns.addEventListener('click', function (e) {
            e.preventDefault();
            linksetTimeout(linkSns);
        });
    });

    let scrollLink = document.querySelectorAll('.scroll-link');
    scrollLink.forEach(function (scrollLink) {
        scrollLink.addEventListener('click', function (e) {
            e.preventDefault();
            if(isPortrait()){
                linksetTimeoutSummary(scrollLink);
            }
        });
    });



//////////////////////////
// Behavior when moving the pointer
/////////////////////////

    // sidebarBodyHammer.on('panleft', function (ev) {
    //
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         panLeftFunctionLandscape(ev, -25, -26, movePercentage)
    //
    //     } else if (angle === "portrait") {
    //
    //         panLeftFunction(ev, -25, -26, movePercentage)
    //
    //     }
    // });
    //
    // sidebarBodyHammer.on('panright', function (ev) {
    //
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         panRightFunctionLandscape(ev, 25, 26, movePercentage)
    //
    //     } else if (angle === "portrait") {
    //
    //         panRightFunction(ev, 25, 26, movePercentage)
    //
    //     }
    // });
    //
    // sidebarBodyHammer.on('panend', function (ev) {
    //
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         panEndFunctionLandscape(ev, -25, 25, movePercentage)
    //
    //     } else if (angle === "portrait") {
    //
    //         panEndFunction(ev, 25, 26, movePercentage)
    //
    //     }
    // });
    //
    // sidebarhandleHammer.on('panleft', function (ev) {
    //
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         panLeftFunctionLandscape(ev, -25, -26, movePercentage)
    //
    //     } else if (angle === "portrait") {
    //
    //         panLeftFunction(ev, -25, -26, movePercentage);
    //
    //     }
    // });
    //
    // sidebarhandleHammer.on('panright', function (ev) {
    //
    //     // console.log('startX2', startX);
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         panRightFunctionLandscape(ev, 25, 26, movePercentage);
    //
    //     } else if (angle === "portrait") {
    //
    //         panRightFunction(ev, 25, 26, movePercentage);
    //
    //     }
    // });
    //
    // sidebarhandleHammer.on('panend', function (ev) {
    //
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         panEndFunctionLandscape(ev, -25, 25, movePercentage);
    //
    //     } else if (angle === "portrait") {
    //
    //         panEndFunction(ev, -25, 25, movePercentage);
    //
    //     }
    // });
    //
    // sidebarBodyHammer.on('panleft', function (ev) {
    //
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         panLeftFunctionLandscape(ev, -25, -26, movePercentage)
    //
    //     } else if (angle === "portrait") {
    //
    //         panLeftFunction(ev, -25, -26, movePercentage)
    //
    //     }
    // });
    //
    // mainHammer.on('panleft', function (ev) {
    //
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         // panLeftFunctionLandscape(ev, -25, -26, movePercentage)
    //
    //     } else if (angle === "portrait") {
    //
    //         panLeftFunction(ev, -25, -26, movePercentage)
    //
    //     }
    // });
    //
    // mainHammer.on('panright', function (ev) {
    //
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         panRightFunctionLandscape(ev, 25, 26, movePercentage)
    //
    //     } else if (angle === "portrait") {
    //
    //         panRightFunction(ev, 25, 26, movePercentage)
    //
    //     }
    // });
    //
    // mainHammer.on('panend', function (ev) {
    //
    //     let measurePosition = measurePositionMobile(ev, startX);
    //     let {movePercentage} = measurePosition;
    //
    //     if (angle === "landscape") {
    //
    //         // panEndFunctionLandscape(ev, -25, 25, movePercentage)
    //
    //     } else if (angle === "portrait") {
    //
    //         panEndFunction(ev, 25, 26, movePercentage)
    //
    //     }
    // });


//////////////////////////////
// clickした場合の挙動
//////////////////////////////
    sideBarMove.sideBarButton.addEventListener('click', function (event) {

        const status = localStorage.getItem('toggleSlider');
        let angle = localStorage.getItem('orientationAngle');

        if (status === "open" && angle === "landscape") {
            MovingGotoCloseMobileLandscape(
                sidemenuButtonColumns,
                sidemenuLeftPositionOpenLandscape,
                sidemenuGridMainColumnsLandscape,
                mainLeftLandscape,
                gridMainColumnsPortrait,
                mainWidthLandscape
            );
        } else if (status === "close" && angle === "landscape") {
            // console.log("sideBarButtonclickLandscape")
            MovingGotoOpenMobileLandscape(
                sidemenuLeftPositionOpenPortrait,
                sidemenuGridMainColumnsLandscape,
                sidemenuMainColumnsLandscape,
                sidemenuButtonColumns,
                gridMainColumnsPortrait,
                mainLeftLandscape,
                mainWidthLandscape
            );
        } else if (status === "open" && angle === "portrait") {
            MovingGotoCloseMobile(
                sidemenuLeftPositionClosePortrait,
                sidemenuGridMainColumnsPortrait,
                sidemenuButtonColumns,
                mainLeftLandscape
            );
        } else if (status === "close" && angle === "portrait") {
            MovingGotoOpenMobile(
                sidemenuLeftPositionOpenPortrait,
                sidemenuGridMainColumnsPortrait,
                sidemenuGridVacantPortrait,
            );
        }
    });

}