export const initQueryselectorSp = () => {
    const body = document.querySelector('body');
    const sideMenu = document.querySelector('.sidebar');
    const sideBarButton = document.querySelector('.opcl-button');
    const main = document.querySelector('.main');
    const sidebarBody = document.querySelector('.sidebar__body');
    const sidebarHandle = document.querySelector('.sidebar__handle');

    return {body, sideMenu, sideBarButton, main, sidebarBody, sidebarHandle}
}
