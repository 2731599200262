import {initQueryselectorPC} from '../init-queryselector-pc';

export const MovingGotoOpenPCInit = (rem) => {

    const {sideMenu, sideBarButton, main} = initQueryselectorPC();

    sideMenu.style.left = "0";
    sideBarButton.style.left = "calc(26rem - 6px)";
    // main.style.left = "32rem";
    main.style.left = "0";
    // sideBarMove.main.style.left = "4rem";
    // main.style.width = "calc(100% - 32rem)";
    main.style.width = "auto";


    sideMenu.style.transition = '0.5s';
    sideBarButton.style.transition = '0.5s';
    main.style.transition = '0.5s';

}