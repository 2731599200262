import { checkLocalStorage } from './base/side-base';
import { initStateTablet } from './tablet/side-tablet-initstate';
import {measurePositionTablet, measurePositionTabletVw} from './tablet/measure-position-tablet';
import {MovingFromOpenTablet} from './tablet/move/moving-from-open-tablet';
import {MovingGotoCloseTablet} from './tablet/move/moving-goto-close-tablet';
import {MovingFromCloseTablet} from './tablet/move/moving-from-close-tablet';
import {MovingGotoOpenTablet} from './tablet/move/moving-goto-open-tablet';
import {MovingGotoCloseTabletHorizonal} from './tablet/move/moving-goto-close-tablet-horizonal';
import {MovingGotoOpenTabletHorizonal} from './tablet/move/moving-goto-open-tablet-horizonal';
import {MovingFromOpenTabletHorizonal} from './tablet/move/moving-from-open-tablet-horizonal';
import {MovingFromCloseTabletlandscape} from './tablet/move/moving-from-close-tablet-horizonal';
import {initQueryselectorTablet} from "./tablet/init-queryselector-tablet";
import {initTabletValue} from "./tablet/init-tablet-value";
import {handleOrientationChange} from "./tablet/tablet-orientation-change";

/////////////////////////
// Tabletの出発点
/////////////////////////

export const tabletSidebar = () => {

const {
    sidebarColumnsPortrait,
    mainColumnsPortrait,
    wholeLeftPositionOpen,
    wholeLeftPositionCloseLandscape,
    wholeLeftPositionClosePortrait,
    sidebarColumnsLandscape,
    mainColumnsLandscape,
    mainColumnsFillwidth
} = initTabletValue();


let startX; // パンの開始位置を格納する変数
const checkStatus = checkLocalStorage();
// const { sideStatus } = checkStatus;

//ここ重要
const sideBarMove = initStateTablet();
// console.log("sideBarMove",sideBarMove)
const {sideMenu} = sideBarMove;

window.addEventListener("orientationchange", handleOrientationChange);

// const sideBarHammer = new Hammer(sideMenu);

/////////////////////////
// PanLeft
/////////////////////////

// sideBarHammer.on('panleft', function (ev) {
//
//     const sideStatus = localStorage.getItem('toggleSlider');
//     const angle = localStorage.getItem('orientationAngle');
//
//     const swipeDistance = measurePositionTabletVw(ev);
//
//
//     if (sideStatus === "open" && angle === "portrait") {
//
//         if (swipeDistance <= 0 && swipeDistance >= -20) {
//
//             MovingFromOpenTablet(swipeDistance, sidebarColumnsPortrait, mainColumnsPortrait);
//
//         } else if (swipeDistance <= -20 && swipeDistance >= -30) {
//
//             MovingGotoCloseTablet(wholeLeftPositionClosePortrait, sidebarColumnsPortrait, mainColumnsFillwidth);
//         }
//
//     }else if (sideStatus === "close" && angle === "portrait") {
//
//             if (swipeDistance >= 0) {
//                 MovingFromCloseTablet(swipeDistance, wholeLeftPositionClosePortrait, sidebarColumnsPortrait, mainColumnsFillwidth);
//             }
//
//     }else if (sideStatus === "open" && angle === "landscape") {
//
//         if (swipeDistance <= 0 && swipeDistance >= -15) {
//             // console.log("sidepanleftinside", swipeDistance)
//             MovingFromOpenTabletHorizonal(swipeDistance, sidebarColumnsLandscape, mainColumnsLandscape);
//
//         } else if (swipeDistance <= -15 && swipeDistance >= -20) {
//
//             MovingGotoCloseTabletHorizonal(wholeLeftPositionCloseLandscape, sidebarColumnsLandscape, mainColumnsFillwidth);
//         }
//
//     }else if (sideStatus === "close" && angle === "landscape") {
//
//         if (swipeDistance >= 0) {
//             MovingFromCloseTabletlandscape(swipeDistance, wholeLeftPositionCloseLandscape, sidebarColumnsLandscape, mainColumnsFillwidth);
//         }
//     }
// });

/////////////////////////
// Panright
/////////////////////////

// sideBarHammer.on('panright', function (ev) {
//
//     const sideStatus = localStorage.getItem('toggleSlider');
//     const angle = localStorage.getItem('orientationAngle');
//     const swipeDistance = measurePositionTabletVw(ev);
//
//     if(sideStatus === "open" && angle === "portrait"){
//         if(swipeDistance <= 0 ){
//     //　一度左に移動し、その後右に移動した場合。左の開いた定位置以上には右にいかないようになっている。
//             MovingFromOpenTablet(swipeDistance, sidebarColumnsPortrait, mainColumnsPortrait);
//         }
//
//     //閉じているときに右に移動した場合の動作
//     }else if (sideStatus === "close" && angle === "portrait") {
//             // console.log("sidestatus", sideStatus)
//         if (swipeDistance >= 0 && swipeDistance <= 20) {
//             MovingFromCloseTablet(swipeDistance, wholeLeftPositionClosePortrait, sidebarColumnsPortrait, mainColumnsFillwidth)
//         } else if (swipeDistance >= 20 && swipeDistance <= 30) {
//             MovingGotoOpenTablet(wholeLeftPositionOpen, sidebarColumnsPortrait, mainColumnsPortrait);
//         }
//
//     }else if(sideStatus === "open" && angle === "landscape") {
//         if (swipeDistance <= 0) {
//     //　一度左に移動し、その後右に移動した場合。
//     // 左の開いた定位置以上には右にいかないようになっている。
//             MovingFromOpenTabletHorizonal(swipeDistance);
//         }
//
//     //閉じているときに右に移動した場合の動作
//     }else if(sideStatus === "close" && angle === "landscape") {
//         if (swipeDistance >= 0 && swipeDistance <= 15) {
//             MovingFromCloseTabletlandscape(swipeDistance, wholeLeftPositionCloseLandscape, sidebarColumnsLandscape, mainColumnsFillwidth)
//         } else if (swipeDistance >= 15 && swipeDistance <= 20) {
//             MovingGotoOpenTabletHorizonal(wholeLeftPositionOpen, sidebarColumnsLandscape, mainColumnsLandscape);
//         }
//
//     }
// });

//////////////////////////////////////////////////
// PanEnd
// As well as other parts, Here is necessary to set the horizontal and
// vertical movement respectively.
//////////////////////////////////////////////////

// sideBarHammer.on('panend', function (ev) {
//
//     const sideStatus = localStorage.getItem('toggleSlider');
//     const angle = localStorage.getItem('orientationAngle');
//     const swipeDistance = measurePositionTabletVw(ev);
//
//     if(sideStatus === "open" && angle==="portrait"){
//         if(swipeDistance <= 0 && swipeDistance >= -30){
//             MovingGotoOpenTablet(wholeLeftPositionOpen, sidebarColumnsPortrait, mainColumnsPortrait)
//         }
//     }else if(sideStatus === "close" && angle==="portrait"){
//         if(swipeDistance >= 0 && swipeDistance <= 30){
//             MovingGotoCloseTablet(wholeLeftPositionClosePortrait, sidebarColumnsPortrait, mainColumnsFillwidth);
//             }
//     }else if(sideStatus === "open" && angle==="landscape") {
//         if (swipeDistance <= 0 && swipeDistance >= -20) {
//             MovingGotoOpenTabletHorizonal(wholeLeftPositionOpen, sidebarColumnsLandscape, mainColumnsLandscape);
//         }
//     }else if(sideStatus === "close" && angle==="landscape") {
//         if (swipeDistance >= 0 && swipeDistance <= 20) {
//             MovingGotoCloseTabletHorizonal(wholeLeftPositionCloseLandscape, sidebarColumnsLandscape, mainColumnsFillwidth);
//         }
//     }
// });

///////////////////////////////////////////////////////
// Buttonをclickしたときの挙動
///////////////////////////////////////////////////////

sideBarMove.sideBarButton.addEventListener('click', function (event) {

    const sideStatus = localStorage.getItem('toggleSlider');
    const angle = localStorage.getItem('orientationAngle');

    if(sideStatus === "open" && angle === "portrait"){

            MovingGotoCloseTablet(wholeLeftPositionClosePortrait, sidebarColumnsPortrait, mainColumnsFillwidth);

    }else if(sideStatus === "close" && angle === "portrait"){

            MovingGotoOpenTablet(wholeLeftPositionOpen, sidebarColumnsPortrait, mainColumnsPortrait);

    }else if(sideStatus === "open" && angle === "landscape") {

           MovingGotoCloseTabletHorizonal(wholeLeftPositionCloseLandscape, sidebarColumnsLandscape, mainColumnsFillwidth);

    }else if(sideStatus === "close" && angle === "landscape") {

           MovingGotoOpenTabletHorizonal(wholeLeftPositionOpen, sidebarColumnsLandscape, mainColumnsLandscape);

    }
});
}
