import {initSpValue} from "./init-sp-value";
import {initQueryselectorSp} from "./init-queryselector-sp";

export const initStateMobile = (sideStatus, angle) => {

    const {body, sideMenu, sideBarButton, main, sidebarBody, sidebarHandle} = initQueryselectorSp();

    const {
        sidemenuLeftPositionClosePortrait,
        sidemenuLeftPositionOpenLandscape,
        sidemenuLeftPositionCloseLandscape,
        sidemenuMainColumnsPortrait,
        sidemenuButtonColumns,
        sidemenuMainColumnsLandscape,
        mainLeftLandscape,
    } = initSpValue();

    ////////////////////////////////////////
    // mobile versionに初期設定でopenはない。
    // そのため、sideStatusがopenになっているのならここでcloseにする。
    ////////////////////////////////////////

    if(sideStatus === 'open' && angle === 'landscape') {
        // sideMenu.style.left = "-30vw";
        sideMenu.style.left = sidemenuLeftPositionOpenLandscape + "vw";
        // sideMenu.style.gridTemplateColumns = "30vw 5rem";
        sideMenu.style.gridTemplateColumns = sidemenuMainColumnsLandscape + "vw" + " " + sidemenuButtonColumns + "rem";
        // sideBarButton.style.left = "-6px";
        // main.style.left = "5rem";

    }else if(sideStatus === 'open' && angle === 'portrait') {


    }else if(sideStatus === 'close' && angle === 'landscape') {
        // sideMenu.style.left = "-30vw";
        sideMenu.style.left = sidemenuLeftPositionCloseLandscape + "vw";
        // sideMenu.style.gridTemplateColumns = "30vw 5rem";
        sideMenu.style.gridTemplateColumns = sidemenuMainColumnsLandscape + "vw" + " " + sidemenuButtonColumns + "rem";
        // sideBarButton.style.left = "-6px";
        main.style.left = mainLeftLandscape + "rem";
        main.style.width = "calc(100vw - " + mainLeftLandscape + "rem)";
        main.style.opacity = "1";

    }else if(sideStatus === 'close' && angle === 'portrait') {
        // sideMenu.style.left = "-70vw";
        sideMenu.style.left = sidemenuLeftPositionClosePortrait + "vw";
        sideMenu.style.gridTemplateColumns = "70vw 5rem";
        sideMenu.style.gridTemplateColumns = sidemenuMainColumnsPortrait + "vw" + " " + sidemenuButtonColumns + "rem";
        // sideBarButton.style.left = "-6px";
        // main.style.left = "5rem";
    }
        sideStatus = "close";
        localStorage.setItem('toggleSlider', sideStatus);

    body.style.opacity = "1";

    return {body, sideMenu, sideBarButton, main, sidebarBody, sidebarHandle}

}


