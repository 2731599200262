import { initQueryselectorTablet } from "../init-queryselector-tablet";

export const MovingGotoOpenTablet = (
        wholeLeftPositionOpen,
        sidebarColumnsPortrait,
        mainColumnsPortrait) => {

    const { body, grid, sideMenu, sideBarButton, main, page, codeArticle } = initQueryselectorTablet();

    // grid.style.left = "0";
    grid.style.left = wholeLeftPositionOpen;
    // grid.style.gridTemplateColumns = "34vw 66vw"
    grid.style.gridTemplateColumns = sidebarColumnsPortrait + "vw " + mainColumnsPortrait + "vw"

    if(page !== null){
        page.style.width = "calc(100% - 30vw - 6px)";
        page.style.transition = '0.5s';
    }

    grid.style.transition = '0.5s';
    sideBarButton.textContent = "CLOSE";

    let toggleSlider = "open";
    localStorage.setItem('toggleSlider', toggleSlider);
}
